<template>
    <section id="secHomeService">
        <v-img
            dark
            :src="require('@/assets/Backgrounds/bg-services-microfresado.jpg')"
            class="text--white"
        >
            <v-container>
                <v-responsive>
                    <v-container>
                        <v-layout wrap>
                            <v-flex 
                                d-flex flex-column md-12 xl-6 align-center 
                                class="mb-responsive"
                            >
                                <div class="w--grid--header--service">
                                    <v-img 
                                        width="70%" 
                                        class="mx-auto"
                                        :src= "img"
                                        data-aos="flip-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    />
                                </div>
                                <div class="w--grid--header--service mt-3">
                                    <h1
                                        class="mb-15 text-h6 text-md-h4 font-weight-black text-center text-uppercase"
                                        data-aos="fade-up"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        {{ title }}
                                    </h1>
                                    <div
                                        data-aos="fade-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                        class="microfresado-text"
                                    >
                                        <p
                                            v-for="(text, i) in  texts"
                                            :key="i"
                                            class="text-center text-lg-h6 text-body-1 font-weight-light paragraph"
                                        >
                                            {{ text }}
                                        </p>
                                        <p class="link-one text-center primary--text text-lg-h6 text-body-1 font-weight-bold">
                                            <a :href=link target="_blank" class="text-decoration-none">*Fuente: {{ link }}</a>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    class="diagram"
                                    v-for="(item, i) in diagram"
                                    :key="i"
                                >
                                    <h1
                                        class="text-center text-h6 text-md-h4 font-weight-black mt-15 title-diagram text-uppercase"
                                        data-aos="fade-up"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >{{ item.title }}</h1>
                                    <div
                                        class="shape-section mb-15"
                                        data-aos="zoom-in"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        <div class="line-diagram mt-12 primary"></div>
                                    </div>
                                    <v-img
                                        :src= "item.img"
                                        width= "70%"
                                        class="mx-auto my-10 img-diagram"
                                        data-aos="flip-left"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    />
                                    <div
                                        data-aos="fade-up"
                                        data-aos-duration="1500"
                                        data-aos-delay="100"
                                        data-aos-offset="300"
                                    >
                                        <p class="footprint text-center text-lg-h6 text-body-1 mt-15 mb-0 font-weight-bold">{{ item.caption }}</p>
                                        <p class="link-one text-center primary--text text-lg-h6 text-body-1 mb-15">
                                            <a :href=item.url target="_blank" class="text-decoration-none font-weight-bold">*Fuente:  {{ item.url }}</a>
                                        </p>
                                    </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-responsive>
            </v-container>
            <v-spacer class="space-service-text" />
        </v-img>
    </section>
</template>

<script>
export default {
    name: 'SectionServicesMicrofresado',
    data() {
        return {
          img: require('@/assets/img/logo-services-microfresado-transparente.png'),
          title: 'MicroFresado',
          texts: [
            'Los pavimentos en la actualidad deben cumplir con características de textura, resistencia al deslizamientos y propiedades ópticas que están directamente ligadas a la seguridad vial. Para que las superficies de los pavimentos cumplan con éstas normas es necesarios realizar labores de microfresado que permitan hacer correcciones del IRI y/o la corrección del coeficiente de rozamiento lateral (CRT),',
            'El microfresado se complementa con un sistema de nivelación, Multiplex, que permite mediante la instalación mínimo de tres sensores en un lado de la máquina y otro que controla la inclinación transversal, obtener los puntos de referencia que garantizan una perfecta nivelación en tres dimensiones. Cualquier irregularidad en el terreno es fácilmente detectada y compensada obteniendo un promedio de los tres sensores de referencia.',
            'El microfresado es una variante del método de fresado en frío estándar, la diferencia es que las picas  en el tambor están dispuestos a menor intervalo. En nuestro caso tenemos un tambor de 274 puntas que se acondiciona en una maquina Wirtgen W2000. Estos tambores de fresado especiales están equipados con un número mucho mayor de picas que los tambores de fresado estándar.'
          ],
          link: 'https://www.samop.es/servicios/microfresado-correccion-iri-y-crt/',
          diagram: [
              {
                title: 'Sistema de nivelación multiplex',
                img: require('@/assets/img/diagram.png'),
                caption: 'Esquema del sistema multiplex, en la fresadora Wirtgen W2000.',
                url: 'https://www.obrasurbanas.es/images/pdf/eiffage_fresado.pdf',
              },

          ],
          links: [
              {url1: ''},
              {url2: ''},
          ]
        }
    },
}
</script>

<style lang="scss">
#secHomeService {
    .microfresado-text {
        p.paragraph:nth-child(3) {
            margin-bottom: 0;
        }
    }
    .diagram {
        .title-diagram {
            margin-left: 25vw;
            margin-right: 25vw;

        }
        .shape-section {
            display: flex;
            justify-content: center;
            .line-diagram {
                position: relative;
                border-top: 1px solid;
                width: 5%;
            }
        }
        @media (max-width: 600px) {
            .img-diagram { width: 50% !important;}
        }
        @media (max-width: 480px) {
            .img-diagram { width: 45% !important;}
        }
    }
}
</style>